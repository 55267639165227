import * as React from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {Link} from 'react-router-dom';

const ReturnLabelsAndDocuments = (props) => {

    const checkout = useSelector(state => state.cart.checkout);

    const haveRepair = _.find(checkout.items, o => {
        return o.orderTypeId == 4
    }) != undefined ? true : false;

    let pdfUrl = '';
    let pdfNames = '';

    if (checkout.paperwork && checkout.paperwork.length > 0) {
        checkout.paperwork.filter(x => x.rgaNumber != undefined && x.rgaNumber != null && x.rgaNumber != '').forEach(x => {
            pdfUrl = pdfUrl + 'ShoppingService/api/v1.0/lineItems/rgapaperwork/' + x.lineItemId + '/' + x.rgaNumber + ','
            pdfNames = pdfNames + x.rgaNumber + ','
        })
    }

    return (
        haveRepair && <div className="print-quote-panel">
            <div className="header-title" style={{position: 'relative'}}>
                <h2>Return Labels & Documents </h2>
                { pdfUrl ? <Link to="/repairs?tab=initiated" className="print-rga-link">Print</Link> : null }  
            </div>
            <div className="quote-instructions rga-text">
                { pdfUrl ? 'RGA Form and Shipping Label' : 'Items in this order require return documents that can take 24-48 hours to prepare.'}
            </div>
            <div className="quote-buttons" />
        </div>
    );

}

export default ReturnLabelsAndDocuments;
