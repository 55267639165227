import * as React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import {Button, Popup} from '@partssourceinc/react-ui-core';
import CartSummary from 'components/CartSummary';
import PageMetaData from 'components/PageMetaData';
import PurchasePanel from 'components/PurchasePanel';
import SalesQuote from 'components/SalesQuote';
import ShippingLabelPreview from 'components/ShippingLabelPreview'
import ReturnLabelsAndDocuments from 'components/ReturnLabelsAndDocuments'
import TrackOrderSMSDialog from 'components/TrackOrderSMSDialog';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';
import 'less/orderconfirmation.less';
import {logEvent, clearTokenInfo} from '../utility';
import * as Socket from 'stores/socket';
import * as DashboardStore from 'stores/Dashboard';

@withRouter
@connect((state) => ({
    checkout: state.cart.checkout, 
    user: state.user,
    sessionStorageAvailable: state.network.sessionStorageAvailable,
    network: state.network
}), 
    _.merge({}, 
        CartStore.actionCreators,
        Socket.actionCreators,
        DashboardStore.actionCreators
    )
)
export default class OrderConfirmation extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...CartStore.ActionShape,
        checkout: CartStore.CheckoutShape,
        user: UserStore.StateShape,
    };
    
    static displayName = 'Cart';

    constructor(props) {
        super(props);
        this.state = {
            showQuote: false,
            showTrackOrderSMSDialog: false,
            hideTrackOrderSMSPrompt: false,
            endSessionAfterCheckout: false,
            showOverlayPopUp: false,
        }
    }

    logOut = async () => {
        const {
            user,
            disconnectWebsocket,
        } = this.props;

        if (user.settings.hasReportsAccess) {
            await this.sisenseLogout();
        }

        disconnectWebsocket();
        this.finishLogout();
    }

   finishLogout = () => {
       const {stopNotifications, sessionStorageAvailable} = this.props;

       if (sessionStorageAvailable) {
           sessionStorage.removeItem('prevUrl');
       }

       stopNotifications().then(() => {
           clearTokenInfo();
           logEvent('My Account', {'menu item': 'logOut'})
       });
   }

   sisenseLogout = () => {
       return new Promise(resolve => {
           let iframe = document.createElement('iframe');
           iframe.setAttribute('id', 'sso-logout'); // assign an id
           iframe.style.visibility = 'hidden';
           iframe.addEventListener('load', resolve);
           document.body.appendChild(iframe); // to place at end of document
           iframe.setAttribute('src', `${process.env.REACT_APP_SISENSE_URL}/api/auth/logout`);

           resolve();
       });
   }

    componentDidMount() {
        let {history, checkout, saveCheckout, network: {tokenInfo: {subscriptionId}}} = this.props;
        if (!checkout.canCheckout) {
            history.push('/cart');
            return;
        }

        checkout.canCheckout = false;
        saveCheckout(checkout); 

        const {checkout: {items, submittedForPO, submittedForApproval, submittedForPunchout, htmlForm, facilitySettings: {isLawson, isEndSessionAfterCheckout}}, user: {settings: {hidePricing}}} = this.props;

        // TODO: Check to see if this data needs sent for hide pricing users
        if (hidePricing || submittedForPO || submittedForApproval || submittedForPunchout) return;
        let sortedOrders = _.orderBy(items, ['orderId'], ['asc']);
        const brOrderId = sortedOrders.length > 0 ? sortedOrders[0].orderId : 0;
        const brBasketValue = document.getElementById('checkout-grand-total').innerHTML.replace('$', '');

        let brBasket = {};
        let brBasketValues = items.map(x => {
            const brProdName = `${x.partNumber} ${x.description} by ${x.oemName}`;
            return {'prod_id': x.oemItemNumber, sku: x.oemItemNumber, 'name': brProdName, 'quantity': x.quantity, 'price': x.price};
        });

        brBasket.items = brBasketValues;

        dataLayer.push({
            event: 'brPageView',
            brPageType: 'other',
            brIsConversion: 1,
            brBasket: brBasket,
            brOrderId: brOrderId,
            brBasketValue: brBasketValue,
        });

        if (htmlForm && isLawson) {
            let serialize = require('form-serialize');
            let punchoutForm = document.querySelector('#epf_generated_form');
 
            if (punchoutForm != null) {                
                let formContent = serialize(punchoutForm);
            
                let punchoutFormData = {
                    action: encodeURIComponent(punchoutForm.action),
                    orderId: brOrderId,
                    content: formContent,
                } 
                    
                axios.post('/ShoppingService/api/v1/cart/punchout/log', punchoutFormData).then(() => {
                    
                }); 
            }

            document.getElementById('epf_generated_form').submit();
        }

        if (isEndSessionAfterCheckout && subscriptionId) {
            this.setState({ endSessionAfterCheckout: isEndSessionAfterCheckout});
            this.logOut();
        }
    }

    renderPayment(items, i) {
        return (
            <div className="contract-payment">
                <div className="contract-badge"><span>CONTRACT</span></div>
                <div className="details">
                    <span className="lbl">Contract #:</span>
                    <span className="val contract">{items[0].contractProName}</span>
                    <span className="lbl">PO #:</span>
                    <span className="val">{items[0].contractPoNumber}</span>
                </div>
            </div>
        )
    }

    onShowTrackOrderSMSDialog (e) {
        this.setState({showTrackOrderSMSDialog: true});

        const {checkout: {items}} = this.props;
        let currOrder = {};

        if (items.length) {
            currOrder = items[items.length - 1];
        }
    }

    renderSmsButton() {
        return (
            <div className="order-detail-info order-detail-sms-button" style={{marginTop: '10px'}} onClick={e => this.onShowTrackOrderSMSDialog(e)}>
                <div className="order-detail-sms-phone-icon-wrapper">
                    <img src={'/images/icn_sms-notification-bubble.png'} alt="Track Order" style={{width: 34, height: 42}} />                                   
                </div>
                <div className="order-detail-sms-wrapper">
                    <div className="order-detail-sms-text-wrapper">
                        Get Text Updates About Your Order
                    </div>
                    <div className="order-detail-sms-orange-button"><Button style={{height: 34}} secondary={true} onClick={e => this.onShowTrackOrderSMSDialog(e)} >TEXT ME</Button></div>
                </div>
            </div>
        )
    }

    handleOverlayClick() {
        this.setState({showOverlayPopUp: true});
    };

    render() {
        const {showQuote, showTrackOrderSMSDialog, hideTrackOrderSMSPrompt, endSessionAfterCheckout, showOverlayPopUp} = this.state;

        const {checkout: {
            billingAddress,
            shippingAddress,
            shippingLocation,            
            paymentInformation,
            paymentInformation: {poNumber},
            items,
            facility,            
            facilitySettings,
            submittedForPO,
            submittedForApproval,
            submittedForPunchout,
            htmlForm,
            shipments,
        },
        user: {settings: {notificationSettings: {mobileNumber, isMobileVerified, isOptedOut, notifications}}}} = this.props;

        const shippingMethod = shipments && shipments.length > 0 ? shipments[0].shippingMethod : this.props.checkout.shippingMethod;
        const selectedShipOption = shipments && shipments.length > 0 ? shipments[0].selectedShipOption : this.props.checkout.selectedShipOption;

        const ShippingAttentionDisplay = shippingLocation.attentionTo ?
            (shippingLocation.includePo ? 'PO#:' + poNumber + ', ' + shippingLocation.attentionTo : shippingLocation.attentionTo).substring(0, 35) :
            (shippingLocation.includePo ? 'PO#:' + poNumber : '').substring(0, 35);
                
        let sortedOrders = _.orderBy(items, ['orderId'], ['asc']);

        const hasNonContractItems = items.some(x => !x.isContractProOption);

        const isMultiShipment = shipments.length > 1;

        let orderMessage = '';
        let orderId = '';
        if (sortedOrders && sortedOrders.length > 0) {
            orderId = sortedOrders[0].orderId;
            orderMessage = 'Order ' + orderId + ' Has Been Successfully Purchased';
            if (submittedForPO && items.every(x => !x.isContractProOption)) orderMessage = 'Order ' + orderId + ' Has Been Submitted for PO';
            if (submittedForApproval) orderMessage = 'Order ' + orderId + ' Has Been Submitted For Approval';
            if (submittedForPunchout) orderMessage = 'Order ' + orderId + ' Has Been Submitted For Punchout';
        }

        let hidePaymentInfo = submittedForPO || submittedForApproval || submittedForPunchout;
        const groupedContractItems = _.groupBy(items.filter(x => x.isContractProOption), x => x.contractPoNumber);
        const hasServiceItems = items.some(x => x.isServiceItem);
        const isApproval = items.some(x => x.isApproval);

        // do not show the SMS prompt if the Requester is not the User that is logged in (the Requester was changed during Checkout)
        let isRequesterChanged = false;
        let orderRequesterId = sortedOrders[0] ? sortedOrders[0].requestorId : 0;
        if (orderRequesterId !== this.props.user.info.contactId) {
            isRequesterChanged = true;
        }

        let showSmsPrompt = false;
        if (!hideTrackOrderSMSPrompt && !hasServiceItems && !isApproval && !isRequesterChanged) {
            showSmsPrompt = !isMobileVerified || !mobileNumber || isOptedOut;
            if (!showSmsPrompt) {
                let ord = _.filter(notifications, {textActive: 1});
                
                if (!ord || ord && ord.length < 5) {
                    showSmsPrompt = true;
                } else {
                    // we check whether the 5 we expect are those 5 below
                    // 'Shipped' / 'Backorder / Est. Ship Date' / 'Delivered' / 'Order Changed' / 'Arriving Today'
                    let arrOrd = _.filter(notifications, (i) => i.notificationType === 6 || i.notificationType === 7 ||
                            i.notificationType === 15 || i.notificationType === 16 || i.notificationType === 27);
                    
                    if (hidePaymentInfo) {
                        // 'Order Rejected' / 'Order Approved'
                        let aprOrd = _.filter(notifications, (i) => i.notificationType === 4 || i.notificationType === 5);
                        arrOrd = _.union(arrOrd, aprOrd);
                    }

                    if (items.some(x => x.orderTypeId === 4)) {
                        // 'Repairs'
                        let arrRepair = _.find(notifications, {notificationType: 26});
                        arrOrd = _.union(arrOrd, arrRepair);
                    }

                    if (items.some(x => x.isExchangeFormularyOption)) {
                        // 'Exchanges'
                        let arrExch = _.find(notifications, {notificationType: 11});
                        arrOrd = _.union(arrOrd, arrExch00000);
                    }

                    showSmsPrompt = !arrOrd.every(n => ord.includes(n));
                }
            }
        }
        return (<div id="order-confirmation-form">
            <PageMetaData 
                title="Order Confirmation" 
                pageType="other" 
                trackAnalytics={false} />
                {endSessionAfterCheckout && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1049, // Make sure it's on top, but below the popup
                        }}
                        onClick={() => this.handleOverlayClick()}
                    />
                )}
            <div className="confirmation-body">
                <i className="fa fa-check" /><h2>{orderMessage}</h2>
                {endSessionAfterCheckout && <div className="confirmation-close-browser"><i className="fa fa-exclamation" /><strong>Your session has ended. Please close your browser and initiate any additional orders through your CMMS.</strong></div>}
                <div className="mobile-only">
                    {hidePaymentInfo ? null :
                        <div className="confirmation-right-column" style={{marginBottom: '20px'}}>
                            <PurchasePanel
                                hideButtons={true}
                                showTotal={true}
                                items={items}
                                facility={facility}                                                                
                                allowPurchase={true}
                                showShipping={true}
                                paymentInfoComplete={true}
                            />
                        </div>}
                </div>
                <div className="confirmation-left-column">
                    <div className="order-details">                        
                        {(hidePaymentInfo && showSmsPrompt) ? 
                            <div className="order-detail-column">                                
                                { this.renderSmsButton()}                                
                            </div>
                            : null}
                        {hidePaymentInfo ? null :
                            <div className="order-detail-column">
                                <div className="order-detail-header">Payment Information</div>
                                {(Object.keys(groupedContractItems) || []).map((x, index) => this.renderPayment(groupedContractItems[x], index))}
                                {hasNonContractItems && <React.Fragment>
                                    {paymentInformation.poNumber ?
                                        <div className="order-detail-info">
                                            <span>P.O.#:</span>
                                            <span>{paymentInformation.poNumber}</span>
                                        </div> : null}
                                    <div className="order-detail-info">
                                        <span>Payment Method:</span>
                                        <span>{paymentInformation.paymentMethod.value === 'PO' ? 'Purchase Order' : 'Credit Card'}</span>
                                    </div>
                                    <div className="order-detail-info">
                                        <span>Billing Address:</span>
                                        <span>{billingAddress.description}</span>
                                    </div></React.Fragment>}
                                {!showSmsPrompt ? null :
                                    <div>
                                        { this.renderSmsButton()}
                                    </div>
                                }
                            </div>}
                    </div>
                    <CartSummary allowEdit={false} checkoutView={true} showShippingInfo={true} />
                    {hidePaymentInfo ? null :
                        <ShippingLabelPreview ShippingAttentionDisplay={ShippingAttentionDisplay} IsMobileOnly={true}
                            Address={shippingAddress} />
                    }
                </div>
                {hidePaymentInfo ? null :
                    <div className="confirmation-right-column">
                        <div className="desktop-only">
                            <PurchasePanel
                                hideButtons={true}
                                showTotal={true}
                                items={items}
                                facility={facility}                                
                                allowPurchase={true}
                                showShipping={true}
                                paymentInfoComplete={true}
                            />
                        </div>
                        <div className="receipt-panel">
                            <div className="receipt">
                            Receipt
                                <span onClick={() => this.setState({showQuote: true})}>PRINT</span>
                            </div>
                        </div>
                        <ReturnLabelsAndDocuments />
                        <ShippingLabelPreview ShippingAttentionDisplay={ShippingAttentionDisplay} IsDesktopOnly={true} Address={shippingAddress} />
                    </div>}
            </div>
            {showQuote ? <SalesQuote onClose={() => this.setState({showQuote: false})} isMultiShipment={isMultiShipment} /> : null}
            {showTrackOrderSMSDialog && 
                <TrackOrderSMSDialog 
                    orderId={orderId} 
                    onClose={() => this.setState({showTrackOrderSMSDialog: false})} 
                    onCloseConfirm={() => this.setState({hideTrackOrderSMSPrompt: true, showTrackOrderSMSDialog: false})} 
                    screenOrigin={'Order Confirmation'} />}
            <div dangerouslySetInnerHTML={{__html: htmlForm}} />
            {endSessionAfterCheckout && showOverlayPopUp && (
                <Popup confirmText="Close"
                    show={true}
                    onConfirm={() => this.setState({showOverlayPopUp: false})}
                    onCancel={() => this.setState({showOverlayPopUp: false})}
                    hideCancel={true}
                >
                    <div className="confirmation_loggedout_popup_content">
                        <strong>Your session has ended. Please close your browser and<br />initiate any additional orders through your CMMS.</strong>
                    </div>
                </Popup>
            )}
        </div>);
    }
}
